<template>
    <div class="friend-chain app-main-card">
        <a-table rowKey="key" :loading="loading" :columns="columns" :data-source="data">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> 博客名</span>
            <span slot="blogLogo" slot-scope="blogLogo">
                <img class="blogLogo" :src="blogLogo" alt="" />
            </span>
            <span slot="blogLinkHot" slot-scope="blogLinkHot, record">
                <a-switch default-checked :checked="blogLinkHot" @change="onChangeLinkShow(blogLinkHot, record)" />
            </span>
        </a-table>
    </div>
</template>
<script>
    import {mapActions} from 'vuex';
    const columns = [
        {
            dataIndex: 'blogName',
            key: 'blogName',
            slots: {title: 'customTitle'},
            scopedSlots: {customRender: 'blogName'},
        },
        {
            title: '博客地址',
            dataIndex: 'blogOrigin',
            key: 'blogOrigin',
        },
        {
            title: 'logo',
            dataIndex: 'blogLogo',
            key: 'blogLogo',
            scopedSlots: {customRender: 'blogLogo'},
        },
        {
            title: '简介',
            key: 'blogDesc',
            dataIndex: 'blogDesc',
        },
        {
            title: 'Action',
            dataIndex: 'blogLinkHot',
            key: 'blogLinkHot',
            scopedSlots: {customRender: 'blogLinkHot'},
        },
    ];

    const data = [

    ];

    export default {
        name: 'friend-chain',
        data() {
            return {
                data,
                columns,
                loading: false
            };
        },
        methods: {
            ...mapActions({
                vxGetFriendLinks: 'friend/getFriendLinks',
                vxChaneLinkShow: 'friend/chaneLinkShow'
            }),
            async getFriendLinks() {
                this.loading = true;
                try {
                    const data = await this.vxGetFriendLinks()
                    this.data = data.map(item => {
                        return {
                            ...item,
                            key: item._id
                        }
                    })
                    console.log(data);
                } catch (error) {
                    this.loading = false;

                }
                this.loading = false;

            },
            async onChangeLinkShow(blogLinkHot, record) {
                await this.vxChaneLinkShow({id: record.key, blogLinkHot: !blogLinkHot})
                this.getFriendLinks()
            }
        },
        mounted() {
            this.getFriendLinks()
        }
    };
</script>

<style lang="less" scoped>
    .blogLogo {
        height: 20px;
    }
</style>
